import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";

const Menu = () => {
  const handleSignUpClick = () => {
    const emailInput = document.getElementById("mce-EMAIL");
    if (emailInput) {
      emailInput.scrollIntoView({ behavior: "smooth", block: "center" });
      emailInput.classList.add("glow-effect");
      emailInput.focus();
      setTimeout(() => {
        emailInput.classList.remove("glow-effect");
      }, 3000);
    }
  };

  return (
    <Navbar
      id="Navbar"
      className="navbar navbar-expand-lg navbar-light theme-nav"
      style={{
        background: 'linear-gradient(to right, rgb(255, 89, 46), rgb(255, 171, 46))',
        height: '60px',
      }}
    >
      <div id="navbar-main" className="container d-flex justify-content-between align-items-center h-100">
        <NavbarBrand className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/Logo_white.png`}
            alt="logo"
            style={{ height: '40px' }}
            className="spinning-logo"
          />
        </NavbarBrand>
        <button
          className="btn p-0 border-0"
          style={{
            background: 'transparent',
            height: '40px',
          }}
          onClick={handleSignUpClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/CTA_REGISTER.png`}
            alt="Sign Up"
            style={{ height: '100%' }}
          />
        </button>
      </div>
    </Navbar>
  );
};

export default Menu;